// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classnames from 'classnames'

import isNull from 'lodash/isNull'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Slider from '../slider'
import '../slider/style.less'

import Gallery from '../gallery'
import '../gallery/style.less'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import SliderRenderer from './slide'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale }) => {
  let { type, subType, lang, content, resources, resource, caption } = block

  if (isNull(lang)) {
    lang = 'en'
  }

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br />
    }

    if (type === 'carousel') {
      returnThis = <Slider nodes={resources} Renderer={SliderRenderer} />
    }

    if (type === 'text') {
      returnThis = (
        <p className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </p>
      )
    }

    if (type === 'title') {
      returnThis = (
        <h2 className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </h2>
      )
    }

    if (type === 'image') {
      const image = getImage(resource)

      if (isNull(caption) === false) {
        returnThis = (
          <div className={classnames('flow with-caption', subType)}>
            <div>
              <GatsbyImage image={image} />
              <p className="caption">{caption}</p>
            </div>
          </div>
        )
      } else {
        returnThis = (
          <div className={classnames('flow', subType)}>
            <div>
              <GatsbyImage image={image} />
            </div>
          </div>
        )
      }
    }

    if (type === 'gallery') {
      const images = []
      map(resources, ({ resource, caption: galleryImageCaption }) => {
        images.push({
          data: resource,
          caption:
            isNull(galleryImageCaption) === false
              ? galleryImageCaption
              : undefined,
          height: resource.childImageSharp.gatsbyImageData.height,
          width: resource.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = <Gallery className={classnames(subType)} images={images} />
    }

    if (type === 'video') {
      returnThis = <Video className={classnames(subType)} url={content} />
    }

    if (type === 'audio') {
      returnThis = <Audio className={classnames(subType)} url={content} />
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'block') {
    returnThis = (
      <div className={classnames('block', 'as-paragraph', subType)}>
        <BlockParser block={childContentBlocks} locale={locale} />
      </div>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
