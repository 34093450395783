// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classnames from 'classnames'

import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Gallery from 'react-photo-gallery'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const imageRenderer = ({ index, left, top, key, photo }) => (
  <Fragment>
    {isUndefined(photo.caption) === true && (
      <GatsbyImage
        image={getImage(photo.data)}
        style={{ height: photo.height, width: photo.width, margin: 2 }}
      />
    )}
    {isUndefined(photo.caption) === false && (
      <div
        className="with-caption"
        style={{ height: photo.height, width: photo.width, margin: 2 }}
      >
        <GatsbyImage image={getImage(photo.data)} />
        <p className="caption">{photo.caption}</p>
      </div>
    )}
  </Fragment>
)

/** GalleryX */
const GalleryX = ({
  images,
  targetRowHeight = 330,
  style = {},
  className = undefined,
}) => (
  <div className={classnames('grid-gallery flow', className)} style={style}>
    <Gallery
      photos={images}
      renderImage={imageRenderer}
      direction="row"
      targetRowHeight={targetRowHeight}
    />
  </div>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default GalleryX
